<template>
  <div id="subpage-content">
    <ProductDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NavbarTrash") }}</h1>
        <div id="int-about-text">
          <img
            height="130"
            width="200"
            :src="getImageUrlbyName('AdS7')"
          />
          <span id="pad" v-html="$t('message.TrashText')"></span>
          <!-- <span id="pad" v-html="$t('message.TrashText1')"></span>
          <span id="pad" v-html="$t('message.TrashText2')"></span>
          <span id="pad" v-html="$t('message.TrashText3')"></span>
          <span id="pad" v-html="$t('message.TrashText4')"></span>
          <span id="pad" v-html="$t('message.TrashText5')"></span>
          <span id="pad" v-html="$t('message.TrashText6')"></span>
          <span id="pad" v-html="$t('message.TrashText7')"></span>
          <span id="pad" v-html="$t('message.TrashText8')"></span>
          <span id="pad" v-html="$t('message.TrashText9')"></span>
          <span id="pad" v-html="$t('message.TrashText10')"></span>
          <span id="pad" v-html="$t('message.TrashText11')"></span>
          <span id="pad" v-html="$t('message.TrashText12')"></span>
          <span v-html="$t('message.TrashText13')"></span>
          <span id="pad" v-html="$t('message.TrashText14')"></span>
          <span id="pad" v-html="$t('message.TrashText15')"></span>
          <span v-html="$t('message.TrashText16')"></span>
          <span v-html="$t('message.TrashText17')"></span>
          <span id="pad" v-html="$t('message.TrashText18')"></span>
          <span id="pad" v-html="$t('message.TrashText19')"></span>
          <span id="pad" v-html="$t('message.TrashText20')"></span>
          <span id="pad" v-html="$t('message.TrashText21')"></span>
          <span id="pad" v-html="$t('message.TrashText22')"></span>
          <span id="pad" v-html="$t('message.TrashText23')"></span>
          <span id="pad" v-html="$t('message.TrashText24')"></span>
          <span id="pad" v-html="$t('message.TrashText25')"></span>
          <span id="pad" v-html="$t('message.TrashText26')"></span>
          <span id="pad" v-html="$t('message.TrashText27')"></span>
          <span id="pad" v-html="$t('message.TrashText28')"></span>
          <span id="pad" v-html="$t('message.TrashText29')"></span>
          <span id="pad" v-html="$t('message.TrashText30')"></span> -->
        </div>
        <v-dialog v-model="dialog" width="500" id="fb-bg">
          <template v-slot:activator="{ on }">
            <v-card id="buy-card">
              <v-btn v-on="on" small id="buy-btn"
                >{{ $t("message.BuyService") }}
              </v-btn>
              <div id="history-card-text">
                {{ $t("message.MakeRequest") }}
              </div>
            </v-card>
          </template>
          <v-card min-height="500">
            <div id="feedback-marg">
              <Feedback />
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import ProductDrawer from "../../../views/Navigations/Products/ProductDrawer";
import Feedback from "../../../components/Feedback";
export default {
  name: "Integration",
  components: {
    ProductDrawer,
    Feedback,
  },
};
</script>